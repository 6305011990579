import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMultiFormProvider } from 'entities/orders';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { NewApplicationForm } from './NewApplicationForm';

interface NewOrderModalProps {
    open?: boolean;
    setOpen?: (arg: boolean) => void;
}

export const NewApplicationModal: FC<NewOrderModalProps> = ({
    open,
    setOpen,
}) => {
    const methods = useForm();

    const { applicationForm } = useMultiFormProvider();

    const onClick = async () => {
        const isValidOrder = await applicationForm.trigger();
        if (!isValidOrder) return;
    };

    return (
        <Modal
            size="lg"
            open={open}
            setOpen={setOpen}
            title="Оформление заказа"
            actionSuccess={
                <Button type="button" onClick={onClick}>
                    Отправить заявку
                </Button>
            }>
            <FormProvider {...methods}>
                <NewApplicationForm />
            </FormProvider>
        </Modal>
    );
};
