import { useApiV1CitiesListQuery } from '@ocenkatech/common/api';
import { inputMasks } from '@ocenkatech/common/lib';
import { FC, useMemo } from 'react';
// eslint-disable-next-line
import { AddressAutoComplete } from 'features/addressAutoComplete';
import { BanksIconList } from 'entities/bank';
import { useMultiFormProvider } from 'entities/orders';
import { Input } from 'shared/ui/Input';
import { AutocompleteControl, SelectControl } from 'shared/ui/Select';

interface NewApplicationFormProps {
    isLoading?: boolean;
}

export const NewApplicationForm: FC<NewApplicationFormProps> = () => {
    const { applicationForm } = useMultiFormProvider();
    const { currentData: cities, isLoading: isLoadingCity } =
        useApiV1CitiesListQuery();
    const optionsCities = useMemo(
        () =>
            cities?.map((city) => ({
                name: city.name,
                value: city.id.toString(),
            })),
        [cities],
    );

    return (
        <div className="grid gap-8">
            <div className="space-y-8 md:flex md:justify-between md:space-x-4 md:space-y-0">
                <SelectControl
                    label="Укажите цель оценки"
                    name="purpose"
                    fieldClassName="w-full"
                    error={applicationForm.formState.errors.purpose}
                    control={applicationForm.control}
                    options={[
                        { value: 'pay_mortgage', name: 'Оплачиваю ипотеку' },
                        { value: 'get_mortgage', name: 'Получаю ипотеку' },
                    ]}
                    rules={{ required: true }}
                />
                <Input
                    fieldClassName="w-full"
                    formKey={'customer.name'}
                    label="Ваше имя"
                    inputProps={{
                        placeholder: 'ФИО',
                    }}
                    error={applicationForm.formState.errors.customer?.name}
                    register={applicationForm.register}
                    registerOptions={{ required: true }}
                />
            </div>
            <div className="space-y-8 md:flex md:justify-between md:space-x-4 md:space-y-0">
                <Input
                    fieldClassName="w-full"
                    formKey={'customer.phone'}
                    label="Номер телефона"
                    mask={inputMasks.phoneNumber}
                    inputProps={{
                        placeholder: '+7',
                    }}
                    register={applicationForm.register}
                    registerOptions={{ required: true }}
                    error={applicationForm.formState.errors.customer?.phone}
                />
                <AutocompleteControl
                    label="Город"
                    name="city"
                    emptyValue="Выберите из списка"
                    control={applicationForm.control}
                    fieldClassName={'w-full'}
                    options={optionsCities}
                    error={applicationForm.formState.errors.city}
                    isLoading={isLoadingCity}
                    rules={{ required: true }}
                />
            </div>
            <div className="space-y-8 md:flex md:justify-between md:space-x-4 md:space-y-0">
                <AddressAutoComplete
                    name={'address'}
                    label="Адрес"
                    emptyValue="Введите ваш адрес"
                    className="w-full"
                    value={
                        applicationForm.getValues('street') ||
                        applicationForm.getValues('address')
                    }
                    control={applicationForm.control}
                    error={applicationForm.formState.errors.address}
                    rules={{ required: true }}
                />
                <Input
                    formKey="promo"
                    label="Промокод"
                    inputProps={{ placeholder: 'Введите промокод' }}
                    register={applicationForm.register}
                    fieldClassName="w-full"
                />
            </div>
            <div>
                <BanksIconList
                    name="bank"
                    control={applicationForm.control}
                    rules={{ required: true }}
                    value={applicationForm.getValues('bank')}
                    error={applicationForm.formState.errors.bank}
                    isOtherBank
                    register={applicationForm.register}
                    setValue={applicationForm.setValue}
                    valueOtherBank={
                        applicationForm.formState.defaultValues?.bankName
                    }
                    nameOtherBank="bankName"
                    errorOtherBank={applicationForm.formState.errors.bankName}
                />
            </div>
            <div className="mt-1 text-[#626262]">
                Нажимая на кнопку “Отправить заявку”, вы соглашаетесь с{' '}
                <a
                    href="https://оценка.рф/privacy"
                    className="text-[#3828E6] hover:underline">
                    Политикой обработки персональных данных
                </a>
                {' и '}
                <a
                    href="https://оценка.рф/offer"
                    className="text-[#3828E6] hover:underline">
                    договором оферты
                </a>
            </div>
        </div>
    );
};
