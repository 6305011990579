import { CustomerOrderWrite, CustomerWrite } from '@ocenkatech/common/api';
import { createContext, PropsWithChildren, FC, useContext } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

type CustomerApplicationWrite = {
    type?: string;
    customer: CustomerWrite;
    city?: number | null;
    bank?: number | null;
    bankName?: string;
    street?: string;
    building?: string;
    residentialComplex?: string;
    apartmentNumber?: string;
    customerConsent?: boolean;
    address?: string;
    promo?: string;
    purpose: 'pay_mortgage' | 'get_mortgage';
};

type MultiFormContextType = {
    orderForm: UseFormReturn<CustomerOrderWrite>;
    applicationForm: UseFormReturn<CustomerApplicationWrite>;
};

const MultiFormContext = createContext<MultiFormContextType | null>(null);

export const useMultiFormProvider = () => {
    const props = useContext(MultiFormContext);

    if (!props) {
        throw new Error(
            'useMultiFormProvider must be used within a MultiFormProvider component',
        );
    }

    return props;
};

export const MultiFormProvider: FC<PropsWithChildren> = ({ children }) => {
    const orderForm = useForm<CustomerOrderWrite>({
        mode: 'onChange',
    });
    const applicationForm = useForm<CustomerApplicationWrite>({
        mode: 'onChange',
    });

    return (
        <MultiFormContext.Provider value={{ orderForm, applicationForm }}>
            {children}
        </MultiFormContext.Provider>
    );
};
